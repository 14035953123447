import { getDataByEnv } from '@/core/utils'

const customDataByEnv = {
  namespace: {
    production: 'f1e7fb02-0a31-4e46-833a-afcd6cb83966',
    development: '9a456ed2-6284-404d-90fe-b18c9ff83a25'
  }
}

export default {
  name: {
    nb: 'Restaurant- og matfag'
  },
  slug: 'rmfag',
  namespace: getDataByEnv('namespace', customDataByEnv),
  analytics: {
    gtm: 'GTM-5H9SGMP',
  },
  assets: {
    logo: '/themes/rmfag/images/logo.svg',
    favicon: '/themes/rmfag/favicon.ico',
  },
  styles: [
    '/themes/rmfag/css/rmfag.css',
    '/common/icons/icons.css',
    'https://cdn.jsdelivr.net/npm/katex@0.13.2/dist/katex.min.css'
  ],
  welcomeMessage: {
    nb: '<h1>Velkommen til Restaurant- og matfag</h1><p>Læremiddelet er utgitt med støtte fra Utdanningsdirektoratet.</p>',
    nn: '<h1>Velkommen til Restaurant- og matfag</h1><p>Læremiddelet er gitt ut med støtte frå Utdanningsdirektoratet.</p>'
  },
  langs: [
    {
      code: 'nb',
      label: 'Bokmål'
    },
    {
      code: 'nn',
      label: 'Nynorsk'
    },
  ],
  mainNav: {
    nb: [
      {
        title: 'Fagbegreper',
        to: '/concepts/begreper'
      },
      {
        title: 'Praktiske oppgaver',
        to: '/articles/yrkesoppgaver',
      },
      {
        title: 'Fagartikler',
        to: '/articles/fagartikler'
      },
      {
        title: 'Oppskrifter',
        to: '/articles/oppskrifter'
      },
      {
        title: 'Multimedia',
        to: '/media/multimedia'
      },
      {
        title: 'MyFoodOffice',
        href: 'https://myfoodoffice.fagbokforlaget.no'
      },
      {
        title: 'Rettelser',
        to: '/files/rettelser',
      },
      {
        title: 'Lærerens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER'
      },
      {
        title: 'Lærerveiledning',
        to: '/articles/laererveiledning',
        accessLevel: 'TEACHER'
      }
    ],
    nn: [
      {
        title: 'Fagomgrep',
        to: '/concepts/begreper'
      },
      {
        title: 'Praktiske oppgåver',
        to: '/articles/yrkesoppgaver',
      },
      {
        title: 'Fagartikler',
        to: '/articles/fagartikler'
      },
      {
        title: 'Oppskrifter',
        to: '/articles/oppskrifter'
      },
      {
        title: 'Multimedium',
        to: '/media/multimedia'
      },
      {
        title: 'MyFoodOffice',
        href: 'https://myfoodoffice.fagbokforlaget.no'
      },
      {
        title: 'Rettingar',
        to: '/files/rettelser',
      },
      {
        title: 'Lærarens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER'
      },
      {
        title: 'Lærarrettleiing',
        to: '/articles/laererveiledning',
        accessLevel: 'TEACHER'
      }
    ]
  }
}
